import $ from 'jquery';
import 'what-input';
import {
    animon
} from 'animon';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();
//animon();

$(document).ready(function () {
    //console.log("tabs slider ready!");

    $('[data-tabs]').on('change.zf.tabs', function () {
        console.log('Those tabs sure did change!');
        $(window).trigger('resize');
    });
    
    $('[data-accordion]').on('down.zf.accordion', function () {
        //console.log('Those tabs sure did change!');
        $(window).trigger('resize');
    });
    
    $('.start').click(function () {
        console.log("I've clicked this!");
        const audio = new Audio("../assets/powersports-img/audio-snarler.mp3");
        audio.play();
        $('.start').fadeOut("6000");
        $('.stop').fadeIn("6000");
        $('.stop').click(function () {
            //const audio = new Audio("../assets/powersports-img/audio-snarler.mp3");
            audio.pause();
            $('.start').fadeIn("6000");
            $('.stop').fadeOut("6000");
        });
    });

});

////////// section-box-tension
$('.section-box-tension .light').on('click', function () {
    $(this).toggleClass("open");
    var index = 1;
    if ($(this).hasClass("open")) {
        index = 0;
    }
    $('.section-box-tension .pic img').eq(index).addClass('cur').siblings().removeClass('cur');
});

$('.section-box-tension .btm .item').hover(function () {
    $('.section-box .light').removeClass("open");
    var index = $(this).index();
    if (index == 0) {
        $('.section-box-tension .tit .light').show();
        $('.section-box-tension .pic img').eq(1).addClass('cur').siblings().removeClass('cur');
    } else {
        $('.section-box-tension .tit .light').hide();
        $('.section-box-tension .pic img').eq(index + 1).addClass('cur').siblings().removeClass('cur');
    }
});

///////// section-box-engine // pro_list06
var mp3 = new Audio("../assets/powersports-img/audio-snarler.mp3");
$('.engine-headbox .item1').on('click', function () {
    if ($(this).hasClass('mp3_start')) {
        mp3.play(); //播放 mp3这个音频对象
        $(this).removeClass('mp3_start').addClass('mp3_stop');
    } else {
        mp3.pause();
        $(this).removeClass('mp3_stop').addClass('mp3_start');
    }
})
mp3.addEventListener('ended', function () {
    $('.engine-headbox .item1').removeClass('mp3_stop').addClass('mp3_start');
}, false);


//////////section-box-qr
$('.flexy-end .right .dl a').hover(function () {
    $(this).addClass('solid').siblings().removeClass('solid');
    var index = $(this).index();
    $('.section-box-qr .flexy-end .right .qrcode img').eq(index).show().siblings().hide();
});

$('.flexy-end .right ul li').on('click', function () {
    var index = $(this).index();
    $(this).addClass('cur').siblings().removeClass('cur');
    $('.flexy-end .left .app img').eq(index + 1).addClass('cur').siblings().removeClass('cur');
    $('.flexy-end .right .top .item').eq(index + 1).stop().fadeIn().siblings().hide();
});


////////section-box-config // pro_list08

// pro_list08
//if ($(window).width() > 992) {
//var proOffset = $('.pro_list08').offset().top;
//var proNextOffset = $('.pro_list09').offset().top;

//$(window).scroll(function () {
//				var scrollTop = $(window).scrollTop();
//				var proDistance = scrollTop - proOffset;
//				var proNextDistance = scrollTop - proNextOffset + $(window).height();
//
//				if (proDistance > 0) {
//					$('.pro_list08').removeClass('abs').addClass('fixed');
//					var opacityPercent = proDistance / $(window).height();
//					$('.pro_list08 .video_cover').css("opacity", 0.5 - opacityPercent);
//					$('.pro_list08 .detail').css("opacity", opacityPercent * 6);
//
//				} else {
//					$('.pro_list08').removeClass('fixed');
//				}
//
//				if (proNextDistance > $(window).height() - $(window).height()) {
//					$('.pro_list08').removeClass('fixed').addClass('abs');
//				}
//
//			})
//		}


$('.section-box-config .list .item').hover(function () {
    var index = $(this).index();
    $('.section-box-config .img_box img').eq(index).addClass('cur').siblings().removeClass('cur');

})

// pro_list05 / section-box-smart
var numInterval;
var numFinshInterval;

var init = $('.pro_list05 .ctrl .item').eq(0).data("num");
var zero = 0;

numInterval = setInterval(function () {
    $('.pro_list05 .num').html(zero);
    ++zero;
    if (zero > init) {
        clearInterval(numInterval);
    }
}, 30)

$('.pro_list05 .ctrl .item').on('click', function () {
    $(this).addClass('cur').siblings().removeClass('cur');
    $('.pro_list05 .media img').eq($(this).index()).addClass('cur').siblings().removeClass('cur');
    clearInterval(numInterval);
    clearInterval(numFinshInterval);
    var num = $(this).data("num") - 10;
    var flag = 0;
    numInterval = setInterval(function () {
        $('.pro_list05 .num').html(flag);
        ++flag;
        if (flag > num) {
            clearInterval(numInterval);
            numFinsh(num);
        }
    }, 30)

    function numFinsh(num) {
        var finshFlag = 1;
        numFinshInterval = setInterval(function () {
            ++num;
            $('.pro_list05 .num').html(num);
            ++finshFlag;
            if (finshFlag > 10) {
                clearInterval(numFinshInterval);
            }
        }, 100)
    }



})
$('.pro_list05 .num').html();
